import React, { useEffect, useState } from 'react' 
import Slider from "react-slick"; 
import {getPrincipalMessage} from '../Service/Api'
import {Link} from 'react-router-dom'
import LazyLoad from "react-lazyload";

// Sample testimonials data
const testimonials = [
  {
    title: 'Principal',
    name: 'Onkar Singh Shekhawat',
    designation: 'Principal',
    message: 'Last decade has seen the school move from one level to higher one. I have witnessed in our students an unparalleled knowledge base, a vibrant dynamism, an acute consciousness about the world around them, a focused ambition and a sustained commitment. I am also proud and pleased to have an experienced and committed team, which has brought the school to its present stature. I, along with my team of devoted workers and dedicated faculty, intend to build on this strong foundation and raise the standards in all spheres, to the zenith.',
    image: '/Images/principal.jpg',
    link: '/PrincipalMessage'
  },
  {
    title: 'Chairman',
    name: 'Sudesh Gupta',
    designation: 'Chairman',
    message: <>Tagore Public School believes in empowering students with knowledge and skills through engaged learning; to ensure pursuit to excellence in the choice of their tertiary education and to make them responsible global citizens, contributing abundantly both to the nation and the world.<br />We strive to offer a world of quality education. The realm extends to innovation, distinction and a blend of unique curricula that will enable harmonious transition of students from school to professionally coveted and aspired education across the world.<br />Our focus is on providing an environment where robust morality and complete knowledge can be developed simultaneously. Our emphasis is on offering a range of opportunities that are structured to nurture confidence and self-esteem in every child.<br />Tagore is an enterprise of our collective realization of our collective endeavour. We are committed to the spiritual and ethical development of our students in a happy, caring and safe environment.</>,
    image: '/Images/chairman.jpg',
    link: '/ChairmanMessage'
  },
  {
    title: 'Director',
    name: 'Director Name',
    designation: 'Director',
    message: <>The captain of this ship, Principal Ms. Manorma Arora, a Rajat Jayanti and State award winner is a dynamic personality with a clarity of vision. She is an educationist par excellence, a disciplinarian and a learned philosopher, known for giving qualitatively and quantitatively best results in Haryana State.<br></br>For her happiness lies in the joy of achievement and in thrill of creative effort. "About two years back she shouldered the responsibility and has amalgamated both effort and intense training. The focus is on rejuvenating and revitalizing this existing system of education.<br></br>The project is her dream and under her guidance "Tagore Academy has carved a niche for itself in the educational arena of the town.<br></br>In the words of the Principal," Success is an outcome of Hardwork, Training and Discipline. This journey is on-going and uphill. There is no looking back, for growth is another name of activity. The School is epitome of universal values, that will produce citizens - responsible and progressive.</> ,
    image: '/Images/director.jpg',
    link: '/DirectorMessage'
  }
];

const Testimonials = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const messages = await getPrincipalMessage();
      const filterData = messages.filter((item) => item.category === "Principal") 
      const filterData1 = messages.filter((item1) => item1.category === "Chairman") 
      const filterData2 = messages.filter((item2) => item2.category === "Director") 
      setData(filterData);
      setData1(filterData1);
      setData1(filterData2);
    };
    fetchData();
  }, []);
  

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true
  };

  return (
    <div className="message-container">
      <Slider {...settings}>
      {data.length > 0 ? data.map((item, index) =>(
          <div key={index} className="testimonial-slide ">
            <div className='messagebox'>
            <div className="testimonial-content messageimg">
            <div className="testimonial">
               <LazyLoad><img
                src={`https://webapi.entab.info/api/image/${item.attachments}`}
                alt="Principal Message"
                className="testimonial-image"
              /></LazyLoad>
              </div>
              </div>
              <div className="testimonial-details messagedesc">
                <div className="messageboximg">
                <p className="testimonial-name"><div dangerouslySetInnerHTML={{ __html: item.name }} /><span><div dangerouslySetInnerHTML={{ __html: item.designation }} /></span></p>
                </div>
                <div className="message-blk">
                <p className="testimonial-message"><div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
                </div>
                 <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/message-shape.png" className='message-shape' alt="Shape"/></LazyLoad>
                <div className="mainbtn button">
                  <Link to="/PrincipalMessage">
                  <button className='button'>
                    <span>Read More <i className="bi bi-arrow-right"></i></span>
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )):(
          <div className="testimonial-slide ">
          <div className='messagebox'>
          <div className="testimonial-content messageimg">
          <div className="testimonial">
             <LazyLoad><img
              src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/principal.jpg"
              alt="Tagore Public School"
              className="testimonial-image"
            /></LazyLoad>
            </div>
            </div>
            <div className="testimonial-details messagedesc">
              {/* <h3 className="testimonial-title">{testimonial.title}</h3> */}
              <div className="messageboximg">
              <p className="testimonial-name">Onkar Singh Shekhawat<span>Principal</span></p>
              </div>
              <div className="message-blk">
              <p className="testimonial-message">Last decade has seen the school move from one level to higher one. I have witnessed in our students an unparalleled knowledge base, a vibrant dynamism, an acute consciousness about the world around them, a focused ambition and a sustained commitment. I am also proud and pleased to have an experienced and committed team, which has brought the school to its present stature. I, along with my team of devoted workers and dedicated faculty, intend to build on this strong foundation and raise the standards in all spheres, to the zenith.</p>
              </div>
               <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/message-shape.png" className='message-shape' alt="Shape"/></LazyLoad>
              <div className="mainbtn button">
                <Link to="/PrincipalMessage">
                <button className='button'>
                  <span>Read More <i className="bi bi-arrow-right"></i></span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
           
      )}
      {data1.length > 0 ? data1.map((item, index) =>(
          <div key={index} className="testimonial-slide ">
            <div className='messagebox'>
            <div className="testimonial-content messageimg">
            <div className="testimonial">
               <LazyLoad><img
                src={`https://webapi.entab.info/api/image/${item.attachments}`}
                alt="Principal Message"
                className="testimonial-image"
              /></LazyLoad>
              </div>
              </div>
              <div className="testimonial-details messagedesc">
                <div className="messageboximg">
                <p className="testimonial-name">{item.name}<span>{item.designation}</span></p>
                </div>
                <div className="message-blk">
                <p className="testimonial-message">{item.message}</p>
                </div>
                 <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/message-shape.png" className='message-shape' alt="Shape"/></LazyLoad>
                <div className="mainbtn button">
                  <Link to="/DirectorMessage">
                  <button className='button'>
                    <span>Read More <i className="bi bi-arrow-right"></i></span>
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )):(
          <div className="testimonial-slide ">
          <div className='messagebox'>
          <div className="testimonial-content messageimg">
          <div className="testimonial">
             <LazyLoad><img
              src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/director.jpg"
              alt="Tagore Public School"
              className="testimonial-image"
            /></LazyLoad>
            </div>
            </div>
            <div className="testimonial-details messagedesc">
              {/* <h3 className="testimonial-title">{testimonial.title}</h3> */}
              <div className="messageboximg">
              <p className="testimonial-name"> Ms. Manorma Arora<span>Director</span></p>
              </div>
              <div className="message-blk">
              <p className="testimonial-message">The captain of this ship, Principal Ms. Manorma Arora, a Rajat Jayanti and State award winner is a dynamic personality with a clarity of vision. She is an educationist par excellence, a disciplinarian and a learned philosopher, known for giving qualitatively and quantitatively best results in Haryana State.<br></br>For her happiness lies in the joy of achievement and in thrill of creative effort. "About two years back she shouldered the responsibility and has amalgamated both effort and intense training. The focus is on rejuvenating and revitalizing this existing system of education.<br></br>The project is her dream and under her guidance "Tagore Academy has carved a niche for itself in the educational arena of the town.<br></br>In the words of the Principal," Success is an outcome of Hardwork, Training and Discipline. This journey is on-going and uphill. There is no looking back, for growth is another name of activity. The School is epitome of universal values, that will produce citizens - responsible and progressive.</p>
              </div>
               <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/message-shape.png" className='message-shape' alt="Shape"/></LazyLoad>
              <div className="mainbtn button">
                <Link to="/DirectorMessage">
                <button className='button'>
                  <span>Read More <i className="bi bi-arrow-right"></i></span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
           
      )}
      {data2.length > 0 ? data2.map((item, index) =>(
          <div key={index} className="testimonial-slide ">
            <div className='messagebox'>
            <div className="testimonial-content messageimg">
            <div className="testimonial">
               <LazyLoad><img
                src={`https://webapi.entab.info/api/image/${item.attachments}`}
                alt="Principal Message"
                className="testimonial-image"
              /></LazyLoad>
              </div>
              </div>
              <div className="testimonial-details messagedesc">
                <div className="messageboximg">
                <p className="testimonial-name">{item.name}<span>{item.designation}</span></p>
                </div>
                <div className="message-blk">
                <p className="testimonial-message">{item.message}</p>
                </div>
                 <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/message-shape.png" className='message-shape' alt="Shape"/></LazyLoad>
                <div className="mainbtn button">
                  <Link to="/ChairmanMessage">
                  <button className='button'>
                    <span>Read More <i className="bi bi-arrow-right"></i></span>
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )):(
          <div className="testimonial-slide ">
          <div className='messagebox'>
          <div className="testimonial-content messageimg">
          <div className="testimonial">
             <LazyLoad><img
              src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/chairman.jpg"
              alt="Tagore Public School"
              className="testimonial-image"
            /></LazyLoad>
            </div>
            </div>
            <div className="testimonial-details messagedesc">
              {/* <h3 className="testimonial-title">{testimonial.title}</h3> */}
              <div className="messageboximg">
              <p className="testimonial-name">Sudesh Gupta<span>Chairman</span></p>
              </div>
              <div className="message-blk">
              <p className="testimonial-message">Tagore Public School believes in empowering students with knowledge and skills through engaged learning; to ensure pursuit to excellence in the choice of their tertiary education and to make them responsible global citizens, contributing abundantly both to the nation and the world.<br />We strive to offer a world of quality education. The realm extends to innovation, distinction and a blend of unique curricula that will enable harmonious transition of students from school to professionally coveted and aspired education across the world.<br />Our focus is on providing an environment where robust morality and complete knowledge can be developed simultaneously. Our emphasis is on offering a range of opportunities that are structured to nurture confidence and self-esteem in every child.<br />Tagore is an enterprise of our collective realization of our collective endeavour. We are committed to the spiritual and ethical development of our students in a happy, caring and safe environment.</p>
              </div>
               <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/message-shape.png" className='message-shape' alt="Shape"/></LazyLoad>
              <div className="mainbtn button">
                <Link to="/ChairmanMessage">
                <button className='button'>
                  <span>Read More <i className="bi bi-arrow-right"></i></span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
           
      )}
      </Slider>
    </div>
  );
};

export default Testimonials;

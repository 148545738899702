import LazyLoad from "react-lazyload";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const CampTour = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Facilities" breadcrumbName="About US " /> 
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>Camps</span> Tour</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                            </div>
                        </div>
                       
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                            <div className="activities_blk">
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/gallery3.png" alt="tagore public school, Ballabhagarh " /></LazyLoad>  
                               
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="activities_blk">
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/gallery3.png" alt="tagore public school, Ballabhagarh " /></LazyLoad>  
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="activities_blk">
                                 <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/gallery3.png" alt="tagore public school, Ballabhagarh " /></LazyLoad>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CampTour

import LazyLoad from "react-lazyload";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const ComingSoon = () => {
    return (
        <>
            <Header />
            <Breadcrumb breadcrumbName="Coming Soon " /> 
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3> Coming Soon</h3>
                            </div>
                        </div> 
                        <div className="col-md-12 mx-auto d-block text-center">
                             <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/comingsoon.jpg" className="img-fluid cm"/></LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ComingSoon

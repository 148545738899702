import React, { useState, useEffect } from 'react'; 
const HomeSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Manually initialize Bootstrap's carousel
    const carouselElement = document.querySelector('#carouselExampleCaptions');
    const carousel = new window.bootstrap.Carousel(carouselElement, {
      interval: 3000,
      ride: 'carousel',
      pause: false,
    });

    // Cleanup function to dispose of the carousel when component unmounts
    return () => {
      carousel.dispose();
    };
  }, []);

  const handleSelect = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="slider-sec">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {Array.from({ length: 5 }).map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={activeIndex === index ? 'active' : ''}
              aria-current={activeIndex === index ? 'true' : 'false'}
              aria-label={`Slide ${index + 1}`}
              onClick={() => handleSelect(index)}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {[ 0, 1, 2, 3, 4].map((i) => (
            <div key={i} className={`carousel-item ${activeIndex === i ? 'active' : ''}`} data-bs-interval="3000">
                <img src={`https://webapi.entab.info/api/image/TAGOREBG/public/Images/sld-${i}.jpg`} className="d-block w-100" alt={`Slide ${i + 1}`} /> 
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="overBox">
        <h2>CREATING A<br />NEW NARRATIVE</h2>
        <p>VIA <span>EDUCATION</span> THROUGH TECHNOLOGY</p> 
      </div>
      <div className="overBox overBox-right">          
        <a href="mailto:tagore_academy@rediffmail.com">
           <img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/email.gif" alt="Tagore public school, Ballabhgarh " /> tagore_academy@rediffmail.com
        </a> 
        <a href="tel:(0129)-2300548, 2241521, 2211217">
            <img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/phone.gif" alt="Tagore public school, Ballabhgarh " />  (0129)-2300548, 2241521, 2211217
        </a> 
      </div>
    </div>
  );
};

export default HomeSlider;

import { useEffect, useState } from 'react';  
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from "react-lazyload";
import {getPrincipalMessage} from'../Service/Api'
const ChairmanMessage = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Chairman Message - Tagore Public School';  
      }, []);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
       <Header />
            <Breadcrumb mid="About" breadcrumbName="Chairman's Message " /> 
                <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='title'>
                        <h3><span>Chairman's</span> Message</h3>
                        </div> 
                    </div>
                </div>
                
                {data?.length > 0 ? data?.filter((div) => div.category === "Chairman").map((item, index) => ( 
                    <div className="clearfix" key={index}>
                      <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt="Tagore Public School Faridabad"/></LazyLoad>
                
                       <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p> 
                      <p className="name"> <div dangerouslySetInnerHTML={{ __html: item.name }} /><span> <div dangerouslySetInnerHTML={{ __html: item.category }} /> </span></p>
              
                 
                </div> 
                )) : emptyData.map((item) => (
                    <div className="clearfix"> 
                    <div class="image_blk float-md-start msgimg">
                     <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/chairman.jpg" className="img-fluid col-md-4  mb-3 " alt="Tagore Public School Faridabad"/></LazyLoad>
                <h5>Sudesh Gupta</h5>
                <p>Chairman</p>
                    </div>
                    <p>Tagore Public School believes in empowering students with knowledge and skills through engaged learning; to ensure pursuit to excellence in the choice of their tertiary education and to make them responsible global citizens, contributing abundantly both to the nation and the world.<br />
                    We strive to offer a world of quality education. The realm extends to innovation, distinction and a blend of unique curricula that will enable harmonious transition of students from school to professionally coveted and aspired education across the world.<br />
                    Our focus is on providing an environment where robust morality and complete knowledge can be developed simultaneously. Our emphasis is on offering a range of opportunities that are structured to nurture confidence and self-esteem in every child.<br />
                    Tagore is an enterprise of our collective realization of our collective endeavour. We are committed to the spiritual and ethical development of our students in a happy, caring and safe environment.
                            </p>
                    </div> 
                    ))}
             </div>
             
        </div> 
       <Footer/> 
     </>
  )
}

export default ChairmanMessage

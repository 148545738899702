import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const HomeEvent = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
     const getData = async () => {
       const datas = await getEvents();
       setData(datas);
     };
     getData();
   }, []);
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <> 
        <div className="eventbg">
          <Slider {...settings}>
          {data?.length > 0 &&
        data?.map((item, index) => ( 
            <div className="item" key={index}>
              <div className="homeeventbox">
                <div className="homeeventboximg">
                  <p><span> <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREBG/public/Images/location.gif" className="img-fluid" alt="Tagore Public School, Ballabhagarh " /></LazyLoad> {item.venue} / {item.time}</span></p>
                   <LazyLoad><img src={`https://webapi.entab.info/api/image/${item?.images}`} className="img-fluid" alt="Tagore Public School, Ballabhagarh " /></LazyLoad> 
                </div>
                <div className="homeeventboxdesc">
                  <div className="homeeventboxtitle">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <div className="homeeventboxbottom">
                      <span>{new Date(item.date).toLocaleString('en-US', { month: 'short' })}, {new Date(item.date).getDate()}, {new Date(item.date).getFullYear()}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            ))} 
          </Slider>
          <div className="mainbtn button">
            <Link to="/Events">
                  <button className='button'>
                    <span>Read More <i className="bi bi-arrow-right"></i></span>
                  </button>
                  </Link>
                </div>
          {/* <button className="button">
            <Link to="/events">
              Read More</Link>
          </button> */}
        </div> 
    </>
  )
}

export default HomeEvent
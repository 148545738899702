 
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb"; 
const AdmissionPolicy = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Admission" breadcrumbName="Admission Policy" /> 
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='title'>
                                <h3><span>Admission</span> Policy</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                        <p>Tagore Academy has a very transparent admission policy and it is based on first come first served basis.The availability of the seats in each class is limited and our admissions team may deny your application if the seats are full in that particular class. </p>

                        <p>The following are the criteria for admission: </p>
                        
                        <h5>Primary</h5>
                        
                        <p><strong>Class I : </strong> The child should be 5 years and 6 Month as on 31th March of the corresponding year. </p>
                        
                        <h5>Middle and Senior</h5>
                        
                        <p>School Leaning Certificate/Transfer Certificate from the previous school and all necessary documents that proves the eligibility of the class admission is applied. </p>
                        
                        <p>Tagore Academy do not take admission in class X and XII until it is a transfer case. If you are applying for any of these two classes, please contact our admission counselor before submitting the Registration Form. </p>
                        
                        <p>For a detailed admission policy please contact school front office. </p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AdmissionPolicy
